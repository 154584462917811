import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BrowserReloadsActions } from '../../browser/actions/browser-reloads.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ConfigActions } from '../actions/config.actions';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from 'src/app/core/services/helpers/helpers.service';
import { CookieService } from 'src/app/core/services/cookies/cookie.service';

@Injectable()
export class ConfigEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private helper: HelpersService,
    private cookieService: CookieService
  ) {}

  $config = createEffect(() =>
    this.actions$.pipe(
      ofType(BrowserReloadsActions['[BrowserActions]BrowserReloadss']),
      mergeMap(() =>
        this.http.post<any>('api/v1/casino/bootstrap', {}).pipe(
          map((res: any) => {
            return ConfigActions['[config]ConfigsSuccess']({
              ...res,
            });
          }),
          catchError((err: any) => of(ConfigActions['[config]ConfigsFailure'](err)))
        )
      )
    )
  );
}
